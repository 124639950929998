import React, { Component } from "react"
import { css } from '@emotion/react'
import { navigate } from '../../helpers/queryNavigate';


import { CardElevation, TitleIcon } from '@findep/mf-landings-core'


import { Grid, Container, Backdrop, Snackbar } from "@material-ui/core"
import { Alert } from '@material-ui/lab';

import CircularProgress from '@material-ui/core/CircularProgress';
import ApartmentIcon from '@material-ui/icons/Apartment';

import { HomeVerification } from '../../services/LandingV4/homeVerification'
import Link from '@material-ui/core/Link';
import { GlobalStateFooterContext } from "../../context/GlobalStateFooterContext";
import getTenant from "../../helpers/getTenant";

const sizeGrl = css`  
   font-size: 16px;
    `
const cntTxtOne = css`  
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
        line-height: 1.5;
        letter-spacing: 0.15px;
    `
const bDrop = css`  
    z-index:9999;
    color: '#fff';
  `
export default class VerificacionDomicilio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flujo: !((typeof window !== 'undefined') && sessionStorage.getItem('flujo') === null) ? (typeof window !== 'undefined') ? JSON.parse(sessionStorage.getItem('flujo')) : '' : '',
      habilita: false,
      bdHabilita: false,
      errorService: false,
      url: ''
    }

    this.linkHandle = this.linkHandle.bind(this)
  }

  static contextType = GlobalStateFooterContext;

  async componentDidMount() {
    //GET TENANT        
    const tenantValue = await getTenant(this.state.flujo.creditApplicationId)
    this.context.setIsDynamic(true)
    this.context.setTenantFooter(tenantValue)
    
    const homeVerification = new HomeVerification(this.state.flujo.creditApplicationId)
    const { data } = await homeVerification.get()
    this.setState({ url: data })
  }

  async linkHandle(event) {
    event.preventDefault()
    try {
      const homeVerification = new HomeVerification(this.state.flujo.creditApplicationId)
      await homeVerification.put()
      window.open(this.state.url, '_blank', 'noreferrer');
      navigate(this.props.pageContext.next.path)
    } catch (error) {
      console.log("error", error)
      this.setState({ errorService: true })
    }
  }

  render() {
    return (
      <Container maxWidth="sm" justify="center" css={sizeGrl}>
        <Grid container justifyContent="center">
          <Grid item style={{ textAlign: "center" }}>
            <TitleIcon icon={ApartmentIcon} title="Verificación de domicilio" size={20} textColor />
          </Grid>
        </Grid>
        <br />
        <CardElevation>
          <Grid container>
            <Grid item  >
              <p css={cntTxtOne}>
                Haz clic en el siguiente enlace para comenzar la verificación virtual de tu domicilio
              </p>
            </Grid>
          </Grid>
          <div>
            <br />
            <Grid container spacing={4} justifyContent="center">
              <Grid item style={{ textAlign: "center" }}>
                <Link href="#" onClick={this.linkHandle}>
                  {this.state.url}
                </Link>
              </Grid>
            </Grid>
          </div>

        </CardElevation>
        <br />
        <Backdrop css={bDrop} open={this.state.bdHabilita} >
          <CircularProgress color="primary" />
        </Backdrop>
        <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
          <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
            Lo sentimos, por el momento el servicio no se encuentra disponible
          </Alert>
        </Snackbar>

        &nbsp;

      </Container>
    )
  }
}